<template>
  <div>

    <!-- Header Alert -->
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        Daftar <b>Status Pembayaran</b> yang ada di {{clinic_name}}
      </div>
      <b-button
        squared
        variant="success"
        @click="$router.push('/masters/payment-statuses/add')"
      >Tambah</b-button>
    </b-alert>

    <!-- Component List -->
    <div class="row">
      <div class="col-md-12">
        <Card>
          <template
            v-slot:body
            v-if="dataLoaded"
          >
            <div>
              <b-table
                striped
                hover
                :items="items"
                :fields="fields"
              >
                <template #table-colgroup="scope">
                  <col
                    v-for="field in scope.fields"
                    :key="field.key"
                    :style="{ width: field.key === 'actions' ? '25%' : '' }"
                  />
                </template>
                <template #cell(actions)='data'>
                  <b-button
                    size="sm"
                    class="mr-1 btn-info"
                    @click="$router.push({ path: '/masters/payment-statuses/detail/' + data.item.id })"
                  > Info </b-button>
                  <b-button
                    size="sm"
                    class="mr-1 btn-success"
                    @click="$router.push({ path: '/masters/payment-statuses/edit/' + data.item.id })"
                  > Edit </b-button>
                  <b-button
                    size="sm"
                    class="btn-danger"
                    @click="deleteData(data.item.id)"
                  > Hapus </b-button>
                </template>
              </b-table>
            </div>
          </template>
        </Card>
      </div>
    </div>

  </div>
</template>

<script>

import Card from "@/view/content/Card.vue"
import module from '@/core/modules/CrudModule.js'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  name: "List",

  components: {
    Card
  },

  data() {
    return {
      // Table Head
      fields: [
        {
          key: "id",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      // Table Items
      items: null,
      // Other
      dataLoaded: false,
      clinic_name : window.configuration == null ? "Medhop" :  window.configuration.clinic_name
    }
  },

  methods: {

    async list() {
      this.items = await module.list('payment-statuses')
      this.dataLoaded = true
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete('payment-statuses/' + id)
      // If Deleted
      if (result) {
        this.list()
      }
    }

  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Status Pembayaran" }])
    // Get Data
    this.list()
  },

}

</script>
